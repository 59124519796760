






































































import { Component, Vue } from "vue-property-decorator";
import { IDeviceUpdate } from "@/interfaces";
import { dispatchGetGroupsByFile, dispatchGetDevice, dispatchUpdateDevice, dispatchDeleteDevice } from "@/store/main/actions";
import { readGroups, readDevice } from '@/store/main/getters';

@Component
export default class Edit extends Vue {
  public dialog = false;
  public valid = false;
  public childGroups = [];
  public channels = ["ch1", "ch2", "ch3", "fusion"];

  public device: IDeviceUpdate = {
    serial_number: undefined,
    channels: [],
    parent_id: 0,
    old_parent_id: 0,
    selected_cells: [],
    project_id: undefined,
    building_id: undefined
  };

  get deviceId() {
    return Number(this.$router.currentRoute.params.id);
  }

  get groupName(){
    let parent =  readGroups(this.$store).find(x=> x.id === this.device.old_parent_id);
    if (parent) {
      return parent.name;
    }
    return '';
  };

  get groups() {
    let groups = readGroups(this.$store);

    let parentGroup = groups.find(x=> x.device_ids.includes(this.deviceId as never));
    if (parentGroup){
      this.device.parent_id = parentGroup.id;
      this.device.old_parent_id = parentGroup.id;
    }
    return groups;
  }
  
  get deviceDetail() {
    return readDevice(this.$store);
  }

  public reset() {
    this.device = <IDeviceUpdate>{ ...readDevice(this.$store) };
    this.device.parent_id = this.device.old_parent_id;
    (this.$refs.form as any).reset();
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async mounted() {
    await dispatchGetDevice(
      this.$store,
      this.deviceId
    );
    if (this.deviceDetail!.building_id){
      await dispatchGetGroupsByFile(this.$store,this.deviceDetail!.file_id);
    }
    this.device = <IDeviceUpdate>{ ...readDevice(this.$store) };
    if (this.device.channels === null){
      this.device.channels = [];
    }  
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.device.channels!.includes(undefined as never) == true){
        this.device.channels = [];
      }
      if (!this.device.selected_cells || this.device.selected_cells!.includes(undefined as never) == true){
        this.device.selected_cells = [];
      }
      await dispatchUpdateDevice(this.$store, { deviceId: this.deviceId, data: this.device});
      this.$router.back();
    }
  }

  public async confirm() {
    if (await this.$validator.validateAll()) {
      if (this.device.channels!.includes(undefined as never) == true){
        this.device.channels = [];
      }
      if (!this.device.selected_cells || this.device.selected_cells!.includes(undefined as never) == true){
        this.device.selected_cells = [];
      }
      await dispatchDeleteDevice(this.$store, { deviceId: this.deviceId, parentId: this.device.old_parent_id});
     // this.$router.back();
    }
  }
}
